import Swiper from 'swiper';
import {Autoplay} from 'swiper/modules';

Swiper.use([Autoplay])
// import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

new Swiper('.brand-swiper', {
    loop: true,
    centeredSlides: true,
    autoplay: {
        delay: 1500,
        disableOnInteraction: true,
        pauseOnMouseEnter: true
    },
    breakpoints: {
        1: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
        984: {
            slidesPerView: 6,
            spaceBetween: 40,
        },
    },
    spaceBetween: 30,
});

new Swiper('.brand-swiper-small', {
    loop: true,
    centeredSlides: true,
    autoplay: {
        delay: 1500,
        disableOnInteraction: true,
        pauseOnMouseEnter: true
    },
    breakpoints: {
        1: {
            slidesPerView: 3,
            spaceBetween: 20,
        },
        984: {
            slidesPerView: 4,
            spaceBetween: 40,
        },
    },
    spaceBetween: 30,
});